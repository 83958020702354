import React from 'react';
import { ICON_NAMES } from './utils';

const IconTemplates = () => (
    <svg style={{display: "none"}} version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <defs>
            <symbol id={ICON_NAMES.DASHBOARD} viewBox="0 0 22 22">
                <path d="M4.63158 22H1.15789C0.850802 22 0.556287 21.878 0.339139 21.6609C0.121992 21.4437 0 21.1492 0 20.8421V11.5789C0 11.2719 0.121992 
                    10.9773 0.339139 10.7602C0.556287 10.543 0.850802 10.4211 1.15789 10.4211H4.63158C4.93867 10.4211 5.23319 10.543 5.45033 10.7602C5.66748 
                    10.9773 5.78947 11.2719 5.78947 11.5789V20.8421C5.78947 21.1492 5.66748 21.4437 5.45033 21.6609C5.23319 21.878 4.93867 22 4.63158 22ZM12.7368 
                    22H9.26316C8.95607 22 8.66155 21.878 8.4444 21.6609C8.22726 21.4437 8.10526 21.1492 8.10526 20.8421V1.15789C8.10526 0.850802 8.22726 0.556287 
                    8.4444 0.339139C8.66155 0.121992 8.95607 0 9.26316 0H12.7368C13.0439 0 13.3384 0.121992 13.5556 0.339139C13.7727 0.556287 13.8947 0.850802 
                    13.8947 1.15789V20.8421C13.8947 21.1492 13.7727 21.4437 13.5556 21.6609C13.3384 21.878 13.0439 22 12.7368 22ZM20.8421 22H17.3684C17.0613 22 
                    16.7668 21.878 16.5497 21.6609C16.3325 21.4437 16.2105 21.1492 16.2105 20.8421V8.10526C16.2105 7.79817 16.3325 7.50366 16.5497 7.28651C16.7668 
                    7.06936 17.0613 6.94737 17.3684 6.94737H20.8421C21.1492 6.94737 21.4437 7.06936 21.6609 7.28651C21.878 7.50366 22 7.79817 22 8.10526V20.8421C22 
                    21.1492 21.878 21.4437 21.6609 21.6609C21.4437 21.878 21.1492 22 20.8421 22Z" />
            </symbol>
            <symbol id={ICON_NAMES.APPLICATION} viewBox="0 0 22 22">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V4H22V3C22 1.34315 20.6569 0 19 0H3ZM22 6H0V19C0 20.6569 1.34315 22 3 
                    22H19C20.6569 22 22 20.6569 22 19V6ZM4 2C4 2.55228 3.55228 3 3 3C2.44772 3 2 2.55228 2 2C2 1.44772 2.44772 1 3 1C3.55228 1 4 1.44772 4 2ZM6 
                    3C6.55228 3 7 2.55228 7 2C7 1.44772 6.55228 1 6 1C5.44772 1 5 1.44772 5 2C5 2.55228 5.44772 3 6 3ZM10 2C10 2.55228 9.55229 3 9 3C8.44771 3 8 
                    2.55228 8 2C8 1.44772 8.44771 1 9 1C9.55229 1 10 1.44772 10 2ZM4 9C3.44772 9 3 9.44771 3 10C3 10.5523 3.44772 11 4 11H18C18.5523 11 19 10.5523 
                    19 10C19 9.44771 18.5523 9 18 9H4ZM3 14C3 13.4477 3.44772 13 4 13H12C12.5523 13 13 13.4477 13 14C13 14.5523 12.5523 15 12 15H4C3.44772 15 3 
                    14.5523 3 14ZM4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H15C15.5523 19 16 18.5523 16 18C16 17.4477 15.5523 17 15 17H4Z" />
            </symbol>
            <symbol id={ICON_NAMES.APPLICATION} viewBox="0 0 22 22">
                <path fillRule="evenodd" clipRule="evenodd" d="M3 0C1.34315 0 0 1.34315 0 3V4H22V3C22 1.34315 20.6569 0 19 0H3ZM22 6H0V19C0 20.6569 1.34315 22 3 
                    22H19C20.6569 22 22 20.6569 22 19V6ZM4 2C4 2.55228 3.55228 3 3 3C2.44772 3 2 2.55228 2 2C2 1.44772 2.44772 1 3 1C3.55228 1 4 1.44772 4 2ZM6 
                    3C6.55228 3 7 2.55228 7 2C7 1.44772 6.55228 1 6 1C5.44772 1 5 1.44772 5 2C5 2.55228 5.44772 3 6 3ZM10 2C10 2.55228 9.55229 3 9 3C8.44771 3 8 
                    2.55228 8 2C8 1.44772 8.44771 1 9 1C9.55229 1 10 1.44772 10 2ZM4 9C3.44772 9 3 9.44771 3 10C3 10.5523 3.44772 11 4 11H18C18.5523 11 19 10.5523 
                    19 10C19 9.44771 18.5523 9 18 9H4ZM3 14C3 13.4477 3.44772 13 4 13H12C12.5523 13 13 13.4477 13 14C13 14.5523 12.5523 15 12 15H4C3.44772 15 3 
                    14.5523 3 14ZM4 17C3.44772 17 3 17.4477 3 18C3 18.5523 3.44772 19 4 19H15C15.5523 19 16 18.5523 16 18C16 17.4477 15.5523 17 15 17H4Z" />
            </symbol>
            <symbol id={ICON_NAMES.RESOURCE} viewBox="0 0 22 22">
                <path d="M18.8571 15.7143C18.2976 15.7172 17.7493 15.8711 17.27 16.1598L14.2741 13.1631C14.7009 12.5224 14.9287 11.7698 14.9287 11C14.9287 10.2302 
                    14.7009 9.47757 14.2741 8.83693L16.2753 6.83572C16.7815 7.07956 17.3577 7.13636 17.9018 6.99604C18.446 6.85572 18.9228 6.52735 19.248 6.06909C19.5732 
                    5.61083 19.7257 5.05227 19.6785 4.49235C19.6312 3.93243 19.3874 3.40729 18.99 3.00996C18.5927 2.61263 18.0676 2.36876 17.5077 2.32154C16.9477 2.27432 
                    16.3892 2.42681 15.9309 2.75199C15.4727 3.07717 15.1443 3.55405 15.004 4.09816C14.8636 4.64226 14.9204 5.21847 15.1643 5.72472L13.1631 7.72593C12.5224 
                    7.29908 11.7698 7.07132 11 7.07132C10.2302 7.07132 9.47757 7.29908 8.83693 7.72593L5.84022 4.72922C6.12876 4.25015 6.28267 3.7021 6.28572 
                    3.14286C6.28572 2.52126 6.10139 1.91362 5.75605 1.39678C5.41071 0.87994 4.91986 0.477113 4.34558 0.239237C3.7713 0.00136174 3.13937 -0.0608773 2.52972 
                    0.0603905C1.92006 0.181658 1.36006 0.480986 0.920523 0.920523C0.480986 1.36006 0.181658 1.92006 0.0603905 2.52972C-0.0608773 3.13937 0.00136174 3.7713 
                    0.239237 4.34558C0.477113 4.91986 0.87994 5.41071 1.39678 5.75605C1.91362 6.10139 2.52126 6.28572 3.14286 6.28572C3.70237 6.28281 4.25071 6.12889 
                    4.73 5.84022L7.72593 8.83693C7.29908 9.47757 7.07132 10.2302 7.07132 11C7.07132 11.7698 7.29908 12.5224 7.72593 13.1631L4.72922 16.1598C4.25014 
                    15.8713 3.70209 15.7174 3.14286 15.7143C2.52126 15.7143 1.91362 15.8986 1.39678 16.244C0.87994 16.5893 0.477113 17.0801 0.239237 17.6544C0.00136174 
                    18.2287 -0.0608773 18.8606 0.0603905 19.4703C0.181658 20.0799 0.480986 20.6399 0.920523 21.0795C1.36006 21.519 1.92006 21.8183 2.52972 21.9396C3.13937 
                    22.0609 3.7713 21.9986 4.34558 21.7608C4.91986 21.5229 5.41071 21.1201 5.75605 20.6032C6.10139 20.0864 6.28572 19.4787 6.28572 18.8571C6.28281 18.2976 
                    6.12889 17.7493 5.84022 17.27L8.83693 14.2741C9.25479 14.5526 9.72245 14.7479 10.2143 14.8492V17.4303C9.69005 17.6156 9.24821 17.9803 8.96686 
                    18.46C8.68552 18.9396 8.58278 19.5032 8.6768 20.0512C8.77083 20.5993 9.05557 21.0964 9.4807 21.4548C9.90582 21.8132 10.444 22.0098 11 22.0098C11.556 
                    22.0098 12.0942 21.8132 12.5193 21.4548C12.9444 21.0964 13.2292 20.5993 13.3232 20.0512C13.4172 19.5032 13.3145 18.9396 13.0331 18.46C12.7518 17.9803 
                    12.31 17.6156 11.7857 17.4303V14.8492C12.2776 14.7479 12.7452 14.5526 13.1631 14.2741L16.1598 17.2708C15.8712 17.7499 15.7173 18.2979 15.7143 
                    18.8571C15.7143 19.4787 15.8986 20.0864 16.244 20.6032C16.5893 21.1201 17.0801 21.5229 17.6544 21.7608C18.2287 21.9986 18.8606 22.0609 19.4703 
                    21.9396C20.0799 21.8183 20.6399 21.519 21.0795 21.0795C21.519 20.6399 21.8183 20.0799 21.9396 19.4703C22.0609 18.8606 21.9986 18.2287 21.7608 
                    17.6544C21.5229 17.0801 21.1201 16.5893 20.6032 16.244C20.0864 15.8986 19.4787 15.7143 18.8571 15.7143ZM11 8.64286C11.4662 8.64286 11.9219 8.7811 
                    12.3096 9.04011C12.6972 9.29912 12.9993 9.66725 13.1777 10.098C13.3561 10.5287 13.4028 11.0026 13.3119 11.4599C13.2209 11.9171 12.9964 12.3371 12.6668 
                    12.6668C12.3371 12.9964 11.9171 13.2209 11.4599 13.3119C11.0026 13.4028 10.5287 13.3561 10.098 13.1777C9.66725 12.9993 9.29912 12.6972 9.04011 
                    12.3096C8.7811 11.9219 8.64286 11.4662 8.64286 11C8.64348 10.375 8.89202 9.77585 9.33394 9.33394C9.77585 8.89202 10.375 8.64348 11 
                    8.64286V8.64286ZM1.57143 3.14286C1.57143 2.83206 1.66359 2.52824 1.83626 2.26982C2.00893 2.0114 2.25436 1.80999 2.5415 1.69105C2.82864 1.57211 3.1446 
                    1.54099 3.44943 1.60162C3.75426 1.66226 4.03426 1.81192 4.25403 2.03169C4.47379 2.25146 4.62346 2.53146 4.68409 2.83629C4.74473 3.14112 4.71361 3.45708 
                    4.59467 3.74422C4.47573 4.03136 4.27432 4.27678 4.0159 4.44945C3.75748 4.62212 3.45366 4.71429 3.14286 4.71429C2.72622 4.71387 2.32676 4.54818 2.03215 
                    4.25357C1.73754 3.95896 1.57185 3.5595 1.57143 3.14286V3.14286ZM3.14286 20.4286C2.83206 20.4286 2.52824 20.3364 2.26982 20.1637C2.0114 19.9911 1.80999 
                    19.7456 1.69105 19.4585C1.57211 19.1714 1.54099 18.8554 1.60162 18.5506C1.66226 18.2457 1.81192 17.9657 2.03169 17.746C2.25146 17.5262 2.53146 17.3765 
                    2.83629 17.3159C3.14112 17.2553 3.45708 17.2864 3.74422 17.4053C4.03136 17.5243 4.27678 17.7257 4.44945 17.9841C4.62212 18.2425 4.71429 18.5463 4.71429 
                    18.8571C4.71387 19.2738 4.54818 19.6732 4.25357 19.9679C3.95896 20.2625 3.5595 20.4282 3.14286 20.4286V20.4286ZM18.8571 20.4286C18.5463 20.4286 18.2425 
                    20.3364 17.9841 20.1637C17.7257 19.9911 17.5243 19.7456 17.4053 19.4585C17.2864 19.1714 17.2553 18.8554 17.3159 18.5506C17.3765 18.2457 17.5262 17.9657 
                    17.746 17.746C17.9657 17.5262 18.2457 17.3765 18.5506 17.3159C18.8554 17.2553 19.1714 17.2864 19.4585 17.4053C19.7456 17.5243 19.9911 17.7257 20.1637 
                    17.9841C20.3364 18.2425 20.4286 18.5463 20.4286 18.8571C20.4279 19.2737 20.2622 19.6731 19.9676 19.9676C19.6731 20.2622 19.2737 20.428 18.8571 
                    20.4286V20.4286Z" />
            </symbol>
            <symbol id={ICON_NAMES.PACKAGE} viewBox="0 0 22 22">
                <path d="M14.0004 9.26628C14.4789 9.54217 15.0904 9.37798 15.3663 8.89955C15.6422 8.42111 15.478 7.80961 14.9996 7.53371L14.0004 9.26628ZM5.99956 2.34371C5.52112 
                    2.06782 4.90962 2.232 4.63372 2.71044C4.35782 3.18887 4.52201 3.80038 5.00044 4.07628L5.99956 2.34371ZM19 15L20 15.001V15H19ZM19 6.99999H20V6.99897L19 
                    6.99999ZM18 5.26999L18.5 4.40396L18.4961 4.40175L18 5.26999ZM11 1.26999L10.5 2.13603L10.5039 2.13824L11 1.26999ZM9 1.26999L9.49614 2.13825L9.5 2.13602L9 
                    1.26999ZM2 5.26999L1.50386 4.40174L1.5 4.40397L2 5.26999ZM1 6.99999L0 6.99897V6.99999H1ZM1 15H-4.76837e-07L5.36442e-07 15.001L1 15ZM2 16.73L1.5 17.596L1.50386 
                    17.5982L2 16.73ZM9 20.73L9.5 19.864L9.49614 19.8618L9 20.73ZM11 20.73L10.5039 19.8617L10.5 19.864L11 20.73ZM18 16.73L18.4961 17.5982L18.5 17.596L18 
                    16.73ZM1.77072 5.09439C1.29266 4.81784 0.680935 4.98121 0.404393 5.45927C0.127851 5.93733 0.291215 6.54906 0.769276 6.8256L1.77072 5.09439ZM10 11.01L9.49928 
                    11.8756C9.80904 12.0548 10.191 12.0548 10.5007 11.8756L10 11.01ZM19.2307 6.8256C19.7088 6.54906 19.8721 5.93733 19.5956 5.45927C19.3191 4.98121 18.7073 4.81784 
                    18.2293 5.09439L19.2307 6.8256ZM9 21.08C9 21.6323 9.44771 22.08 10 22.08C10.5523 22.08 11 21.6323 11 21.08H9ZM11 11C11 10.4477 10.5523 9.99999 10 
                    9.99999C9.44771 9.99999 9 10.4477 9 11H11ZM14 13C14 13.5523 14.4477 14 15 14C15.5523 14 16 13.5523 16 13H14ZM16 9C16 8.44772 15.5523 8 15 8C14.4477 8 14 
                    8.44772 14 9H16ZM14.9996 7.53371L5.99956 2.34371L5.00044 4.07628L14.0004 9.26628L14.9996 7.53371ZM20 15V6.99999H18V15H20ZM20 6.99897C19.9995 6.47288 19.8606 
                    5.95618 19.5973 5.50071L17.8658 6.5016C17.9535 6.65342 17.9998 6.82566 18 7.00102L20 6.99897ZM19.5973 5.50071C19.334 5.04524 18.9556 4.66701 18.5 4.40397L17.5 
                    6.13602C17.6519 6.2237 17.778 6.34978 17.8658 6.5016L19.5973 5.50071ZM18.4961 4.40175L11.4961 0.401751L10.5039 2.13824L17.5039 6.13824L18.4961 4.40175ZM11.5 
                    0.403968C11.0439 0.140663 10.5266 0.00204468 10 0.00204468V2.00204C10.1755 2.00204 10.348 2.04825 10.5 2.13602L11.5 0.403968ZM10 0.00204468C9.47339 0.00204468 
                    8.95606 0.140663 8.5 0.403968L9.5 2.13602C9.65202 2.04825 9.82446 2.00204 10 2.00204V0.00204468ZM8.50386 0.401751L1.50386 4.40175L2.49614 6.13824L9.49614 
                    2.13824L8.50386 0.401751ZM1.5 4.40397C1.04439 4.66701 0.665971 5.04524 0.402693 5.50071L2.13423 6.5016C2.22199 6.34978 2.34813 6.2237 2.5 6.13602L1.5 
                    4.40397ZM0.402693 5.50071C0.139415 5.95618 0.000540137 6.47288 5.36442e-07 6.99897L2 7.00102C2.00018 6.82566 2.04647 6.65342 2.13423 6.5016L0.402693 5.50071ZM0 
                    6.99999V15H2V6.99999H0ZM5.36442e-07 15.001C0.000540137 15.5271 0.139415 16.0438 0.402693 16.4993L2.13423 15.4984C2.04647 15.3466 2.00018 15.1743 2 
                    14.999L5.36442e-07 15.001ZM0.402693 16.4993C0.665971 16.9547 1.04439 17.333 1.5 17.596L2.5 15.864C2.34813 15.7763 2.22199 15.6502 2.13423 15.4984L0.402693 
                    16.4993ZM1.50386 17.5982L8.50386 21.5982L9.49614 19.8618L2.49614 15.8618L1.50386 17.5982ZM8.5 21.596C8.95606 21.8593 9.47339 21.9979 10 21.9979V19.9979C9.82446 
                    19.9979 9.65202 19.9517 9.5 19.864L8.5 21.596ZM10 21.9979C10.5266 21.9979 11.0439 21.8593 11.5 21.596L10.5 19.864C10.348 19.9517 10.1755 19.9979 10 
                    19.9979V21.9979ZM11.4961 21.5982L18.4961 17.5982L17.5039 15.8618L10.5039 19.8618L11.4961 21.5982ZM18.5 17.596C18.9556 17.333 19.334 16.9547 19.5973 
                    16.4993L17.8658 15.4984C17.778 15.6502 17.6519 15.7763 17.5 15.864L18.5 17.596ZM19.5973 16.4993C19.8606 16.0438 19.9995 15.5271 20 15.001L18 14.999C17.9998 
                    15.1743 17.9535 15.3466 17.8658 15.4984L19.5973 16.4993ZM0.769276 6.8256L9.49928 11.8756L10.5007 10.1444L1.77072 5.09439L0.769276 6.8256ZM10.5007 
                    11.8756L19.2307 6.8256L18.2293 5.09439L9.49928 10.1444L10.5007 11.8756ZM11 21.08V11H9V21.08H11ZM16 13V9H14V13H16Z" />
            </symbol>
            <symbol id={ICON_NAMES.VULNERABILITY} viewBox="0 0 24 24">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 3.06189V4H12V3.06189C15.6187 3.51314 18.4869 6.38128 18.9381 10H18V12H18.9381C18.4869 15.6187 15.6187 18.4869 
                    12 18.9381V18H10V18.9381C6.38128 18.4869 3.51314 15.6187 3.06189 12H4V10H3.06189C3.51314 6.38128 6.38128 3.51314 10 3.06189ZM12 1.04938C16.7244 1.51844 
                    20.4816 5.27558 20.9506 10H22V12H20.9506C20.4816 16.7244 16.7244 20.4816 12 20.9506V22H10V20.9506C5.27558 20.4816 1.51844 16.7244 1.04938 
                    12H0V10H1.04938C1.51844 5.27558 5.27558 1.51844 10 1.04938V0H12V1.04938ZM11.9845 6.25863L12.5668 5.25008C12.7048 5.01094 13.0106 4.929 13.2498 5.06707C13.4889 
                    5.20514 13.5709 5.51094 13.4328 5.75008L12.7217 6.98172C12.8985 7.27996 12.9999 7.62808 12.9999 7.99989V8.99989H8.99992V7.99989C8.99992 7.63825 9.0959 7.29903 
                    9.26378 7.00631L8.56677 5.79905C8.4287 5.55991 8.51064 5.25411 8.74978 5.11604C8.98893 4.97797 9.29472 5.05991 9.4328 5.29905L9.99392 6.27094C10.2894 6.09862 
                    10.6332 5.99989 10.9999 5.99989C11.3579 5.99989 11.6938 6.09392 11.9845 6.25863ZM7.96127 8.51715C7.9036 9.42539 8.18915 9.88592 8.9339 
                    9.99999H9.99983V10.9999C9.99983 11.5522 10.4475 11.9999 10.9998 11.9999C11.5521 11.9999 11.9998 11.5522 11.9998 10.9999V9.99999H13.0659C13.8106 9.88592 14.0962 
                    9.42539 14.0385 8.51715C14.0215 8.24938 14.221 8.01828 14.4841 8.00097C14.7472 7.98367 14.9743 8.18671 14.9913 8.45449C15.0602 9.53995 14.7112 10.3031 13.9998 
                    10.6954V12H15.5001C15.7762 12 16.0001 12.2238 16.0001 12.5C16.0001 12.7761 15.7762 13 15.5001 13L13.9998 13C13.9998 13.4226 13.9125 13.8248 13.7548 
                    14.1896C14.6303 14.5345 15.0677 15.3418 14.9913 16.5454C14.9743 16.8132 14.7472 17.0162 14.4841 16.9989C14.221 16.9816 14.0215 16.7505 14.0385 16.4827C14.0922 
                    15.6359 13.8477 15.1783 13.2107 15.0278C12.6623 15.6254 11.8748 16 10.9998 16C10.1249 16 9.33737 15.6254 8.78898 15.0278C8.15207 15.1783 7.90751 15.636 7.96127 
                    16.4827C7.97828 16.7505 7.77877 16.9816 7.51566 16.9989C7.25256 17.0162 7.02548 16.8132 7.00848 16.5454C6.93206 15.3418 7.36947 14.5345 8.24495 14.1896C8.08725 
                    13.8249 7.99985 13.4226 7.99985 13L6.5 13C6.22386 13 6 12.7761 6 12.5C6 12.2238 6.22386 12 6.5 12H7.99985V10.6954C7.28854 10.3031 6.93956 9.53992 7.00848 
                    8.45449C7.02548 8.18671 7.25256 7.98367 7.51566 8.00097C7.77877 8.01828 7.97828 8.24938 7.96127 8.51715Z" />
            </symbol>
            <symbol id={ICON_NAMES.SCAN} viewBox="0 0 23 22">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.16833 10.389H5.61277C5.84425 10.389 6.05585 10.5198 6.15937 10.7269L6.75486 11.9178L8.09613 
                    8.34113C8.29351 7.81479 9.03691 7.81166 9.23871 8.33633L11.5669 14.3896L13.5718 5.36758C13.6996 4.79228 14.4874 4.71342 14.7268 5.25196L17.0099 
                    10.389H20.2794V2.44446H3.16833V10.389ZM3.16833 11.6112V19.5555H20.2794V11.6112H16.6128C16.3713 11.6112 16.1524 11.469 16.0543 11.2483L14.3628 
                    7.44227L12.3204 16.6327C12.1891 17.2236 11.3708 17.2845 11.1535 16.7195L8.67556 10.2768L7.4072 13.6591C7.21984 14.1588 6.52704 14.1951 6.2884 
                    13.7179L5.23509 11.6112H3.16833ZM0.723877 2.11538C0.723877 0.969132 1.62379 0 2.78638 0H20.6614C21.824 0 22.7239 0.969132 22.7239 
                    2.11538V19.8846C22.7239 21.0309 21.824 22 20.6614 22H2.78638C1.62379 22 0.723877 21.0309 0.723877 19.8846V2.11538Z" />
            </symbol>
            <symbol id={ICON_NAMES.X_MARK} viewBox="0 0 22 22">
                <path d="M11,9.6l4.3-4.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4L12.4,11l4.3,4.3
                    c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0L11,12.4l-4.3,4.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4L9.6,11L5.3,6.7
                    c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0L11,9.6z"/>
            </symbol>
            <symbol id={ICON_NAMES.CHECK_MARK} viewBox="0 0 22 22">
                <path d="M16.9875149,5.6731233 L9.05000019,13.4082501 L6.11192553,10.6483265 C5.72481656,10.28469 
                    5.12097179,10.2871065 4.73678564,10.6538297 L4.71087502,10.6785625 C4.33788616,11.0345973 4.3241422,11.6255878 
                    4.68017702,11.9985767 C4.69118712,12.0101111 4.70249091,12.0213615 4.71407726,12.0323169 L9.05000019,16.1321252 L18.3955222,7.04232642 
                    C18.7704132,6.67769369 18.7787291,6.07819131 18.4140964,5.70330036 C18.4059265,5.69490063 18.3976014,5.68665326 18.3891254,5.67856252 
                    L18.3759081,5.66594601 C17.986588,5.2943223 17.3729721,5.29749438 16.9875149,5.6731233 Z"/>
            </symbol>
            <symbol id={ICON_NAMES.STROKE} viewBox="0 0 22 1">
                <path fillRule="evenodd" clipRule="evenodd" d="M22 1.00049H0V0.000488281H22V1.00049Z" />
            </symbol>
            <symbol id={ICON_NAMES.SORT} viewBox="0 0 9 5">
                <path d="M4.5 4.75L8.07235 0.625001H0.927645L4.5 4.75Z" />
            </symbol>
            <symbol id={ICON_NAMES.CHEVRON_RIGHT} viewBox="0 0 14 15">
                <path fillRule="evenodd" clipRule="evenodd" d="M3.82735 2.08257C3.45878 2.47054 3.45776 3.07904 3.82735 3.46701L7.66825 7.49985L3.82735 11.5327C3.45776 
                    11.9207 3.45878 12.5302 3.82735 12.9171C4.22246 13.3306 4.88405 13.3306 5.27815 12.9171L10.4494 7.49985L5.27917 2.08359C4.88405 1.66908 4.22246 
                    1.66908 3.82837 2.08359L3.82735 2.08257Z" />
            </symbol>
            <symbol id={ICON_NAMES.CHEVRON_RIGHT_DOUBLE} viewBox="0 0 14 15">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.43307 4.21162C1.21567 3.98736 1.22025 3.63152 1.44108 3.41069L1.77403 3.07774C1.99715 2.85462 2.35985 
                    2.85462 2.58297 3.07774L7.00636 7.49998L2.58297 11.9222C2.35985 12.1453 1.99715 12.1453 1.77403 11.9222L1.44108 11.5893C1.22025 11.3685 1.21682 
                    11.0126 1.43307 10.7884L4.59214 7.49998L1.43421 4.21162H1.43307Z" />
                <path fillRule="evenodd" clipRule="evenodd" d="M7.15401 4.21162C6.93662 3.98736 6.9412 3.63152 7.16202 3.41069L7.49498 3.07774C7.71809 2.85462 8.0808 
                    2.85462 8.30391 3.07774L12.7273 7.49998L8.30391 11.9222C8.0808 12.1453 7.71809 12.1453 7.49498 11.9222L7.16202 11.5893C6.9412 11.3685 6.93776 11.0126 
                    7.15401 10.7884L10.3131 7.49998L7.15516 4.21162H7.15401Z" />
            </symbol>
            <symbol id={ICON_NAMES.ARROW_LEFT} viewBox="0 0 16 15">
                <path fillRule="evenodd" clipRule="evenodd" d="M11.6266 14.1902C12.0478 13.748 12.049 13.0514 11.6266 12.6092L7.23703 8.00019L11.6266 
                    3.39123C12.049 2.94784 12.0478 2.25124 11.6266 1.80901C11.1739 1.33645 10.419 1.33645 9.96856 1.80901L4.05859 8.00019L9.96856 14.1902C10.419 
                    14.6639 11.1751 14.6639 11.6255 14.1902H11.6266Z" />
            </symbol>
            <symbol id={ICON_NAMES.REFRESH} viewBox="0 0 22 24">
                <path fillRule="evenodd" clipRule="evenodd" d="M11 2.96902V0.421521C11 0.049521 11.247 -0.0789789 11.5495 0.135021L17 3.96452L11.5495 7.80302C11.247 
                    8.01702 11 7.88802 11 7.51602V4.96902C6.313 4.96902 2.5 8.78202 2.5 13.469C2.5 18.156 6.313 21.969 11 21.969C15.687 21.969 19.5 18.156 19.5 
                    13.469C19.5 10.8075 18.2685 8.43002 16.3475 6.87052L18.0295 5.68552C20.156 7.60652 21.5 10.3775 21.5 13.469C21.5 19.268 16.799 23.969 11 
                    23.969C5.201 23.969 0.5 19.268 0.5 13.469C0.5 7.66952 5.201 2.96902 11 2.96902Z" />
            </symbol>
            <symbol id={ICON_NAMES.FILTER} viewBox="0 0 18 14">
                <path fillRule="evenodd" clipRule="evenodd" d="M0.816406 1.54552C0.816406 0.792794 1.42732 0.181885 2.18004 0.181885H15.8164C16.5691 
                    0.181885 17.18 0.792794 17.18 1.54552C17.18 2.29825 16.5691 2.90916 15.8164 2.90916H2.18004C1.42732 2.90916 0.816406 2.29825 
                    0.816406 1.54552ZM4.90732 7.00007C4.90732 6.24734 5.51822 5.63643 6.27095 5.63643H11.7255C12.4782 5.63643 13.0891 6.24734 13.0891 
                    7.00007C13.0891 7.75279 12.4782 8.3637 11.7255 8.3637H6.27095C5.51822 8.3637 4.90732 7.75279 4.90732 7.00007ZM8.99822 
                    11.091C8.2455 11.091 7.63459 11.7019 7.63459 12.4546C7.63459 13.2073 8.2455 13.8182 8.99822 13.8182C9.75095 13.8182 10.3619 
                    13.2073 10.3619 12.4546C10.3619 11.7019 9.75095 11.091 8.99822 11.091Z" />
            </symbol>
            <symbol id={ICON_NAMES.ALERT} viewBox="0 0 18 18">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.3876 0.574092C9.62218 -0.191364 8.3788 -0.191364 7.61237 0.574092L0.574092 7.61237C-0.191364 8.37782 
                    -0.191364 9.6212 0.574092 10.3876L7.61237 17.4259C8.37782 18.1914 9.6212 18.1914 10.3876 17.4259L17.4259 10.3876C18.1914 9.62218 18.1914 8.3788 17.4259 
                    7.61237L10.3876 0.574092ZM8.01865 5.07458C8.01865 4.53288 8.45829 4.09323 9 4.09323C9.54171 4.09323 9.98135 4.53288 9.98135 5.07458V9.98135C9.98135 
                    10.5231 9.54171 10.9627 9 10.9627C8.45829 10.9627 8.01865 10.5231 8.01865 9.98135V5.07458ZM8.01865 12.9254C8.01865 12.3837 8.45829 11.9441 9 
                    11.9441C9.54171 11.9441 9.98135 12.3837 9.98135 12.9254C9.98135 13.4671 9.54171 13.9068 9 13.9068C8.45829 13.9068 8.01865 13.4671 8.01865 12.9254Z" />
            </symbol>
            <symbol id={ICON_NAMES.BUG} viewBox="0 0 20 20">
                <path fillRule="evenodd" clipRule="evenodd" d="M18.1818 10.0001C18.1818 14.5187 14.5187 18.1819 10 18.1819C5.48131 18.1819 1.81818 14.5187 1.81818 
                    10.0001C1.81818 5.48136 5.48131 1.81824 10 1.81824C14.5187 1.81824 18.1818 5.48136 18.1818 10.0001ZM11.0154 5.1368L11.6028 4.11946C11.744 3.87488 12.0567 
                    3.79108 12.3013 3.93228C12.5459 4.07349 12.6297 4.38624 12.4885 4.63082L11.7708 5.87387C11.9455 6.17086 12.0457 6.51693 12.0457 
                    6.8864V7.95458H7.95476V6.8864C7.95476 6.52731 8.04939 6.19033 8.21508 5.89902L7.51183 4.68097C7.37063 4.43639 7.45443 4.12364 7.69901 3.98243C7.94359 
                    3.84122 8.25633 3.92502 8.39754 4.16961L8.96309 5.14917C9.25537 4.98196 9.59391 4.8864 9.95476 4.8864H10.0457C10.3975 4.8864 10.7282 4.97726 11.0154 
                    5.1368ZM6.89243 7.46083C6.83346 8.38958 7.12539 8.86057 7.88682 8.97732H8.9774V10.0228C8.9774 10.5751 9.42511 11.0228 9.9774 11.0228H10.0229C10.5751 
                    11.0228 11.0229 10.5751 11.0229 10.0228V8.97732H12.1135C12.8749 8.86057 13.1668 8.38958 13.1078 7.46083C13.0904 7.18697 13.2945 6.95062 13.5636 
                    6.93292C13.8327 6.91523 14.0649 7.12289 14.0823 7.39674C14.1528 8.50687 13.7958 9.28737 13.0683 9.68863V11.0228H14.6025C14.8849 11.0228 15.1138 11.2517 
                    15.1138 11.5341C15.1138 11.8166 14.8849 12.0455 14.6025 12.0455L13.0683 12.0455C13.0683 12.4778 12.9789 12.8891 12.8176 13.2622C13.7131 13.6149 14.1604 
                    14.4406 14.0823 15.6715C14.0649 15.9454 13.8327 16.1531 13.5636 16.1354C13.2945 16.1177 13.0904 15.8813 13.1078 15.6074C13.1628 14.7414 12.9127 14.2734 
                    12.2612 14.1195C11.7004 14.7306 10.895 15.1137 10.0002 15.1137C9.10529 15.1137 8.29991 14.7306 7.73906 14.1195C7.08759 14.2734 6.83744 14.7414 6.89243 
                    15.6074C6.90982 15.8813 6.70578 16.1177 6.43669 16.1354C6.1676 16.1531 5.93537 15.9454 5.91798 15.6715C5.83982 14.4405 6.28721 13.6149 7.18265 
                    13.2621C7.02136 12.8891 6.93197 12.4778 6.93197 12.0455H5.39795C5.11553 12.0455 4.88659 11.8166 4.88659 11.5341C4.88659 11.2517 5.11553 11.0228 5.39795 
                    11.0228H6.93197V9.68865C6.20444 9.2874 5.84749 8.50689 5.91798 7.39674C5.93537 7.12289 6.1676 6.91523 6.43669 6.93292C6.70578 6.95062 6.90982 7.18697 
                    6.89243 7.46083Z" />
            </symbol>
            <symbol id={ICON_NAMES.BANDAID} viewBox="0 0 35 34">
                <path d="M13.6465 1.66474L16.2628 4.28099L4.78275 15.761L2.16475 13.1465C1.09824 12.0799 0.499092 10.6333 0.499092 9.12499C0.499092 7.61666 1.09824 6.17009 
                    2.16475 5.10349L5.6035 1.66474C6.6701 0.598235 8.11667 -0.000915527 9.625 -0.000915527C11.1333 -0.000915527 12.5799 0.598235 13.6465 1.66474ZM21.3535 
                    32.3335L18.7373 29.7172L30.2173 18.2372L32.8335 20.8517C33.3619 21.3799 33.781 22.007 34.0669 22.6971C34.3529 23.3873 34.5 24.1271 34.5 24.8741C34.5 
                    25.6212 34.3529 26.3609 34.0669 27.0511C33.781 27.7413 33.3619 28.3683 32.8335 28.8965L29.3965 32.3335C28.3299 33.4 26.8833 33.9992 25.375 
                    33.9992C23.8667 33.9992 22.4201 33.4 21.3535 32.3335ZM2.1665 20.8535C1.09999 21.9201 0.500842 23.3667 0.500842 24.875C0.500842 26.3833 1.09999 27.8299 
                    2.1665 28.8965L5.6035 32.3335C6.6701 33.4 8.11667 33.9992 9.625 33.9992C11.1333 33.9992 12.5799 33.4 13.6465 32.3335L32.8353 13.1465C33.9018 12.0799 
                    34.5009 10.6333 34.5009 9.12499C34.5009 7.61666 33.9018 6.17009 32.8353 5.10349L29.3965 1.66474C28.3299 0.598235 26.8833 -0.000915527 25.375 
                    -0.000915527C23.8667 -0.000915527 22.4201 0.598235 21.3535 1.66474L2.1665 20.8517V20.8535ZM18.8125 11.75C18.8125 12.0981 18.6742 12.4319 18.4281 
                    12.6781C18.1819 12.9242 17.8481 13.0625 17.5 13.0625C17.1519 13.0625 16.8181 12.9242 16.5719 12.6781C16.3258 12.4319 16.1875 12.0981 16.1875 
                    11.75C16.1875 11.4019 16.3258 11.0681 16.5719 10.8219C16.8181 10.5758 17.1519 10.4375 17.5 10.4375C17.8481 10.4375 18.1819 10.5758 18.4281 
                    10.8219C18.6742 11.0681 18.8125 11.4019 18.8125 11.75ZM12.25 18.3125C11.9019 18.3125 11.5681 18.1742 11.3219 17.9281C11.0758 17.6819 10.9375 17.3481 
                    10.9375 17C10.9375 16.6519 11.0758 16.3181 11.3219 16.0719C11.5681 15.8258 11.9019 15.6875 12.25 15.6875C12.5981 15.6875 12.9319 15.8258 13.1781 
                    16.0719C13.4242 16.3181 13.5625 16.6519 13.5625 17C13.5625 17.3481 13.4242 17.6819 13.1781 17.9281C12.9319 18.1742 12.5981 18.3125 12.25 18.3125ZM17.5 
                    18.3125C17.1519 18.3125 16.8181 18.1742 16.5719 17.9281C16.3258 17.6819 16.1875 17.3481 16.1875 17C16.1875 16.6519 16.3258 16.3181 16.5719 
                    16.0719C16.8181 15.8258 17.1519 15.6875 17.5 15.6875C17.8481 15.6875 18.1819 15.8258 18.4281 16.0719C18.6742 16.3181 18.8125 16.6519 18.8125 17C18.8125 
                    17.3481 18.6742 17.6819 18.4281 17.9281C18.1819 18.1742 17.8481 18.3125 17.5 18.3125ZM24.0625 17C24.0625 17.3481 23.9242 17.6819 23.6781 
                    17.9281C23.4319 18.1742 23.0981 18.3125 22.75 18.3125C22.4019 18.3125 22.0681 18.1742 21.8219 17.9281C21.5758 17.6819 21.4375 17.3481 21.4375 
                    17C21.4375 16.6519 21.5758 16.3181 21.8219 16.0719C22.0681 15.8258 22.4019 15.6875 22.75 15.6875C23.0981 15.6875 23.4319 15.8258 23.6781 
                    16.0719C23.9242 16.3181 24.0625 16.6519 24.0625 17ZM17.5 23.5625C17.1519 23.5625 16.8181 23.4242 16.5719 23.1781C16.3258 22.9319 16.1875 22.5981 
                    16.1875 22.25C16.1875 21.9019 16.3258 21.5681 16.5719 21.3219C16.8181 21.0758 17.1519 20.9375 17.5 20.9375C17.8481 20.9375 18.1819 21.0758 18.4281 
                    21.3219C18.6742 21.5681 18.8125 21.9019 18.8125 22.25C18.8125 22.5981 18.6742 22.9319 18.4281 23.1781C18.1819 23.4242 17.8481 23.5625 17.5 23.5625Z" />
            </symbol>
            <symbol id={ICON_NAMES.START_TIME} viewBox="0 0 22 22">
                <path d="M18 5h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h1V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v2h7V3a1 1 0 0 
                    1 1-1h1a1 1 0 0 1 1 1v2zM5 7a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H5zm2 2h2a1 1 0 0 1 1 1v2a1 1 0 0 
                    1-1 1H7a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1z"/>
            </symbol>
            <symbol id={ICON_NAMES.END_TIME} viewBox="0 0 22 22">
                <path d="M18 5h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V7a2 2 0 0 1 2-2h1V3a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v2h7V3a1 1 0 0 
                    1 1-1h1a1 1 0 0 1 1 1v2zM5 7a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h13a1 1 0 0 0 1-1V8a1 1 0 0 0-1-1H5zm9 5h2a1 1 0 0 1 1 1v2a1 1 0 0
                    1-1 1h-2a1 1 0 0 1-1-1v-2a1 1 0 0 1 1-1z"/>
            </symbol>
            <symbol id={ICON_NAMES.PLUS} viewBox="0 0 20 20">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.7868 4.19954C10.7868 3.76299 10.4329 3.40909 9.99633 3.40909C9.55978 3.40909 9.20588 3.76299 9.20588 
                    4.19954V8.87584H4.19951C3.76296 8.87584 3.40906 9.22974 3.40906 9.66629C3.40906 10.1028 3.76296 10.4567 4.19951 10.4567H9.20588V15.4631C9.20588 
                    15.8997 9.55978 16.2536 9.99633 16.2536C10.4329 16.2536 10.7868 15.8997 10.7868 15.4631V10.4567H15.4631C15.8996 10.4567 16.2535 10.1028 16.2535 
                    9.66629C16.2535 9.22974 15.8996 8.87584 15.4631 8.87584H10.7868V4.19954Z" />
            </symbol>
            <symbol id={ICON_NAMES.DELETE} viewBox="0 0 21 20">
                <path fillRule="evenodd" clipRule="evenodd" d="M5.38637 16.1364C5.38637 17.2614 6.15342 18.1818 7.09126 18.1818H13.9088C14.8466 18.1818 15.6136 17.2614 
                    15.6136 16.1364V5.90909H5.38637V16.1364ZM16.6364 2.84091H14.0796L13.0568 1.81818H7.94319L6.92047 2.84091H4.36365V4.88637H16.6364V2.84091Z" />
            </symbol>
            <symbol id={ICON_NAMES.EDIT} viewBox="0 0 22 22">
                <path d="M17.6,9.1c0.4,0.4,0.4,1,0,1.4l-8,7.9h-5c-0.3,0-0.6-0.4-0.6-0.6L4,12.8l8-8c0.4-0.4,1-0.4,1.4,0l0,0
                    l0.6-0.5c0.4-0.4,1-0.4,1.4,0l2.8,2.8c0.4,0.4,0.4,1,0,1.4L17.6,9.1z M6.3,13.3l2.8,2.8l6.4-6.4L12.7,7L6.3,13.3z"/>
            </symbol>
            <symbol id={ICON_NAMES.INFO} viewBox="0 0 22 22">
                <path d="M10,9.4C10,8.6,10.4,8,11,8s1,0.6,1,1.4v7.1c0,0.8-0.4,1.4-1,1.4s-1-0.6-1-1.4V9.4z"/>
                <circle cx="11" cy="5" r="1"/>
            </symbol>
            <symbol id={ICON_NAMES.COG} viewBox="0 0 18 17">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.13502 5.1983C0.380486 6.43737 1.10139 7.71736 1.96956 8.19281L2.14774 8.29008L2.14228 8.50008C2.14228 
                    8.56826 2.14501 8.63917 2.14774 8.71007L1.96956 8.80735C1.10139 9.28279 0.381395 10.5628 1.13502 11.8019L2.14137 13.4546C2.845 14.61 4.21589 14.6618 
                    5.05406 14.2027L5.21588 14.1136C5.4177 14.2445 5.62769 14.3664 5.84224 14.4791V14.51C5.84224 15.6209 6.7195 16.6818 7.99403 16.6818H10.0067C11.2813 
                    16.6818 12.1585 15.6209 12.1585 14.51V14.4791C12.3731 14.3664 12.5831 14.2445 12.7849 14.1136L12.9467 14.2027C13.7849 14.6618 15.1558 14.61 15.8594 
                    13.4546L16.8657 11.8019C17.6194 10.5628 16.8994 9.28279 16.0312 8.80735L15.853 8.71007L15.8585 8.50008L15.853 8.29008L16.0312 8.19281C16.8994 
                    7.71736 17.6194 6.43737 16.8657 5.1983L15.8594 3.54559C15.1558 2.39015 13.7849 2.33924 12.9467 2.79742L12.7849 2.88651C12.5831 2.7556 12.3731 
                    2.63379 12.1576 2.52106V2.49015C12.1585 1.37926 11.2822 0.318359 10.0076 0.318359H7.99403C6.71859 0.318359 5.84224 1.37926 5.84224 
                    2.49015V2.52106C5.62769 2.63379 5.4177 2.7556 5.21497 2.88651L5.05406 2.79742C4.21589 2.33924 2.845 2.39015 2.14137 3.54559L1.13502 5.1983ZM5.38406 
                    5.05103L4.18226 4.39285C4.00408 4.29558 3.78408 4.33922 3.69135 4.49194L2.68591 6.14465C2.59318 6.29738 2.66228 6.49919 2.84045 6.59737L4.10953 
                    7.29191C4.01135 7.67827 3.95862 8.08372 3.95862 8.50008C3.95862 8.91644 4.01135 9.32189 4.10953 9.70824L2.84045 10.4028C2.66228 10.501 2.59318 
                    10.7028 2.68591 10.8555L3.69226 12.5082C3.78499 12.6609 4.00499 12.7046 4.18317 12.6073L5.38315 11.9491C6.00042 12.5718 6.78131 13.0355 7.65858 
                    13.2727V14.51C7.65858 14.7055 7.80857 14.8636 7.99403 14.8636H10.0067C10.1922 14.8636 10.3422 14.7055 10.3422 14.51V13.2727C11.2194 13.0355 12.0003 
                    12.5718 12.6167 11.9491L13.8167 12.6073C13.9958 12.7046 14.2158 12.6609 14.3076 12.5082L15.3148 10.8555C15.4076 10.7028 15.3385 10.501 15.1603 
                    10.4028L13.8912 9.70824C13.9894 9.32189 14.0421 8.91644 14.0421 8.50008C14.0421 8.08372 13.9894 7.67827 13.8912 7.29191L15.1603 6.59737C15.3385 
                    6.49919 15.4076 6.29738 15.3148 6.14465L14.3085 4.49194C14.2158 4.33922 13.9958 4.29558 13.8176 4.39285L12.6167 5.05103C12.0003 4.42831 11.2194 
                    3.96468 10.3422 3.72832V2.49015C10.3422 2.2947 10.1922 2.13652 10.0058 2.13652H7.99494C7.80948 2.13652 7.65858 2.2947 7.65858 2.49015V3.72832C6.78222 
                    3.96468 6.00132 4.42831 5.38497 5.05103H5.38406ZM8.99674 11.2273C10.5013 11.2273 11.7203 10.0064 11.7203 8.50008C11.7203 6.99373 10.5013 5.77284 
                    8.99674 5.77284C7.49222 5.77284 6.27314 6.99373 6.27314 8.50008C6.27314 10.0064 7.49222 11.2273 8.99674 11.2273ZM9.90491 8.50008C9.90491 9.00189 
                    9.49855 9.40916 8.99674 9.40916C8.49493 9.40916 8.08857 9.00189 8.08857 8.50008C8.08857 7.99826 8.49493 7.591 8.99674 7.591C9.49855 7.591 9.90491 
                    7.99826 9.90491 8.50008Z" />
            </symbol>
            <symbol id={ICON_NAMES.CLOCK} viewBox="0 0 22 22">
                <path fillRule="evenodd" clipRule="evenodd" d="M17.6 11C17.6 14.6451 14.6451 17.6 11 17.6C7.35492 17.6 4.4 14.6451 4.4 11C4.4 7.35492 7.35492 4.4 11 
                    4.4C14.6451 4.4 17.6 7.35492 17.6 11ZM20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 
                    20 11ZM9.875 7.5659V11.8294C9.875 12.4181 10.3522 12.8953 10.9409 12.8953H10.9411H14.1387C14.7274 12.8953 15.2046 12.4181 15.2046 11.8294C15.2046 
                    11.2408 14.7274 10.7635 14.1387 10.7635H12.0068V7.5659C12.0068 6.97723 11.5296 6.50001 10.9409 6.50001C10.3522 6.50001 9.875 6.97723 9.875 7.5659Z" />
            </symbol>
        </defs>
    </svg>
);

export default IconTemplates;