import React from 'react';
import { isEmpty } from 'lodash';
import DetailsPageWrapper from 'components/DetailsPageWrapper';
import TabbedPageContainer from 'components/TabbedPageContainer';
import TabDetails from './TabDetails';
import TabCvss from './TabCvss';

import './vulnerability-details.scss';

const VulnerabilityDetailsContent = ({data}) => {
    const {cvss} = data || {};

    return (
        <TabbedPageContainer
            items={[
                {id: "details", title: "Vulnerability details", isIndex: true, component: () => <TabDetails data={data} />},
                {id: "cvss", title: "CVSS", path: "cvss", disabled: isEmpty(cvss), component: () => <TabCvss cvss={cvss} /> }
            ]}
        />
    )
}

const VulnerabilityDetails = () => (
    <DetailsPageWrapper
        title="Vulnerability information"
        backTitle="Vulnerabilities"
        detailsContent={VulnerabilityDetailsContent}
        getUrl={({vulnerabilityId, packageId}) => `vulnerabilities/${vulnerabilityId}/${packageId}`}
        getReplace={params => {
            const packageId = params["packageId"];
            const vulnerabilityId = params["vulnerabilityId"];
            const innerTab = params["*"];
            
            return !!innerTab ? `/${vulnerabilityId}/${packageId}/${innerTab}` : `/${vulnerabilityId}/${packageId}`;
        }}
    />
)

export default VulnerabilityDetails;
