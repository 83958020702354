import React from 'react';
import TitleValueDisplay, { TitleValueDisplayColumn } from 'components/TitleValueDisplay';
import SeverityTag from 'components/SeverityTag';
import { getItemsString, formatDate } from 'utils/utils';
import { PackagesLink, ApplicationsLink, ApplicationResourcesLink } from '../utils';
import { CvssScoreMessage } from '../utils';

const SeverityDisplay = ({severity, cvssSeverity, cvssScore}) => (
    <div className="severity-display-wrapper">
        <SeverityTag severity={severity} />
        {(!!cvssSeverity && cvssSeverity !== severity) &&
            <div className="cvss-score-alert"><CvssScoreMessage cvssScore={cvssScore} cvssSeverity={cvssSeverity} /></div>
        }
    </div>
)

const TabDetails = ({data}) => {
    const {vulnerability, scanDate, description, cvss, links} = data || {};
    const {cvssV3Metrics} = cvss || {};
    const {baseScore, severity: cvssSeverity} = cvssV3Metrics || {};
    const {vulnerabilityName, severity, packageName, packageVersion, fixVersion, applications, applicationResources,
        reportingScanners, source, packageID} = vulnerability || {};
    
    return (
        <div className="vulnerability-tab-details">
            <TitleValueDisplayColumn>
                <TitleValueDisplay title="Name">{vulnerabilityName}</TitleValueDisplay>
                <TitleValueDisplay title="Severity">
                    <SeverityDisplay severity={severity} cvssSeverity={cvssSeverity} cvssScore={baseScore} />
                </TitleValueDisplay>
                <TitleValueDisplay title="Scan Date">{formatDate(scanDate)}</TitleValueDisplay>
                <TitleValueDisplay title="Package Name">{packageName}</TitleValueDisplay>
                <TitleValueDisplay title="Package Version">
                    <PackagesLink packageVersion={packageVersion} packageName={packageName} />
                </TitleValueDisplay>
                <TitleValueDisplay title="Fix Version">{fixVersion}</TitleValueDisplay>
            </TitleValueDisplayColumn>
            <TitleValueDisplayColumn>
                <TitleValueDisplay title="Description" withOpen={!!description} defaultOpen>{description}</TitleValueDisplay>
                <TitleValueDisplay title="Links" withOpen={!!links} defaultOpen>
                    {!!links && links.map((link, index) => <div key={index}><a href={link} target="_blank" rel="noopener noreferrer">{link}</a></div>)}
                </TitleValueDisplay>
                <TitleValueDisplay title="Reporting Scanners">{getItemsString(reportingScanners)}</TitleValueDisplay>
                <TitleValueDisplay title="Source">{source}</TitleValueDisplay>
            </TitleValueDisplayColumn>
            <TitleValueDisplayColumn>
                <TitleValueDisplay title="Applications">
                    <ApplicationsLink packageID={packageID} applications={applications} vulnerabilityName={vulnerabilityName} />
                </TitleValueDisplay>
                <TitleValueDisplay title="Application Resources">
                    <ApplicationResourcesLink packageID={packageID} applicationResources={applicationResources} vulnerabilityName={vulnerabilityName} />
                </TitleValueDisplay>
            </TitleValueDisplayColumn>
        </div>
    )
}

export default TabDetails;