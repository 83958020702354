import React from 'react';
import ProgressBar from 'components/ProgressBar';
import TitleValueDisplay, { TitleValueDisplayRow } from 'components/TitleValueDisplay';
import { toCapitalized } from 'utils/utils';
import { SEVERITY_ITEMS } from 'utils/systemConsts';

const ScoreBar = ({title, score}) => (
    <div className="cvss-score-bar-display">
        <div className="cvss-score-bar-title">{title}</div>
        <ProgressBar percent={score*10} hidePercent isSmall />
        <div className="cvss-score-bar-score">{score}</div>
    </div>
)

const BaseScoreDisplay = ({severity, score}) => {
    const {label} = SEVERITY_ITEMS[severity] || {};

    return (
        <div className="cvss-base-score-display">
            <div className="cvss-base-score-content">
                <div className="cvss-base-score">{score}</div>
                <div className="cvss-base-severity">{label || severity}</div>
            </div>
        </div>
    )
}

const TabCvss = ({cvss}) => {
    const {cvssV3Metrics, cvssV3Vector} = cvss || {};
    const {baseScore, exploitabilityScore, impactScore, severity} = cvssV3Metrics || {};
    const {attackComplexity, attackVector, availability, confidentiality, integrity, privilegesRequired, scope, userInteraction, vector} = cvssV3Vector || {};

    return (
        <div className="vulnerability-tab-cvss">
            <div className="cvss-scores-wrapper">
                <div className="cvss-base-score-display-wrapper">
                    <BaseScoreDisplay score={baseScore} severity={severity} />
                </div>
                <div className="cvss-other-scores-display">
                    <ScoreBar score={exploitabilityScore} title="Exploitability score" />
                    <ScoreBar score={impactScore} title="Impact score" />
                </div>
            </div>
            <div className="vulnerability-tab-cvss-vector">
                <TitleValueDisplayRow>
                    <TitleValueDisplay title="Attack complexity">{toCapitalized(attackComplexity)}</TitleValueDisplay>
                    <TitleValueDisplay title="Attack vector">{toCapitalized(attackVector)}</TitleValueDisplay>
                    <TitleValueDisplay title="Privileges required">{toCapitalized(privilegesRequired)}</TitleValueDisplay>
                    <TitleValueDisplay title="User interaction">{toCapitalized(userInteraction)}</TitleValueDisplay>
                </TitleValueDisplayRow>
                <TitleValueDisplayRow>
                    <TitleValueDisplay title="Scope">{toCapitalized(scope)}</TitleValueDisplay>
                    <TitleValueDisplay title="Confidentiality">{toCapitalized(confidentiality)}</TitleValueDisplay>
                    <TitleValueDisplay title="Integrity">{toCapitalized(integrity)}</TitleValueDisplay>
                    <TitleValueDisplay title="Availability">{toCapitalized(availability)}</TitleValueDisplay>
                </TitleValueDisplayRow>
                <TitleValueDisplayRow>
                    <TitleValueDisplay title="Vector">{vector}</TitleValueDisplay>
                </TitleValueDisplayRow>
            </div>
        </div>
    )
}

export default TabCvss;